import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrash, faCheck, faTrashRestore} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

import { isTodoReoccuring } from '../TodoView/Todo/Todo';


class TodoListItem extends Component {

  constructor(props){
    super(props);

    this.remove = this.remove.bind(this);
    this.complete = this.complete.bind(this);
    this.restore = this.restore.bind(this);
    this.edit = this.edit.bind(this);
    this.getDateString = this.getDateString.bind(this);
  }

  remove(){
    this.props.removeTodo(this.props.todo);
  }

  complete(){
    this.props.completeTodo(this.props.todo);
  }

  restore(){
    this.props.restoreTodo(this.props.todo);
  }

  edit(){
    this.props.editTodo(this.props.todo);
  }

  getDateString(){
    const dueAt = this.props.todo.dueAt;
    if(dueAt === "Stack") return dueAt;
    else if(moment(dueAt).isValid()) return moment(dueAt).format("DD/MM/YY")
    else return dueAt;
  }

  render() {

    const reoccuring = isTodoReoccuring(this.props.todo);
    const completed = this.props.todo.completed;

    const varStyle = {
      background: this.props.todo.color,
      opacity: 0.85,
      borderWidth: `${this.props.lastInList ? "0" : "0px 0px 1px 0px"}`,
    }

    return(
      <Row className="TodoListItem" style={{...styles.todoListItem, ...varStyle}}>

        {
          !reoccuring ?
          <Col xs={2} lg={1} style={styles.col}>
            {
              !reoccuring && !completed ?
              <button style={styles.actionButton} className="btn btn-dark" onClick={this.edit}><FontAwesomeIcon icon={faPencilAlt}/></button>
              : null
            }
          </Col>
          : null
        }

        <Col xs={7 + this.props.colWidthOffsetXS} lg={8 + this.props.colWidthOffsetLG} style={styles.col}>{this.props.todo.text}</Col>
        <Col xs={3} lg={3} style={styles.col}>
          { reoccuring ?
            this.props.todo.reoccuring :
            this.getDateString()
          }
        </Col>

        {
          <Col xs={2} lg={1} style={styles.col}>
            {
              !completed ?
              <button
                style={styles.actionButton}
                className="btn btn-dark"
                onClick={reoccuring ? this.remove: this.complete}>
                <FontAwesomeIcon
                  icon={reoccuring ? faTrash : faCheck}/>
              </button>
              :
              <button
                style={styles.actionButton}
                className="btn btn-dark"
                onClick={this.restore}>
                <FontAwesomeIcon
                  icon={faTrashRestore}/>
              </button>
            }
          </Col>
        }

      </Row>
    )
  }
}

const styles = {
  todoListItem: {
    borderStyle: "dashed",
    borderColor: "grey",
    height: 50
  },
  col: {
    textAlign: "center",
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 2,
    paddingRight: 2,
    lineHeight: 2.3,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap"
  },
  actionButton: {
    width: "85%",
    height: 38
  }
}

export default TodoListItem;

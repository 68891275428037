import React, { Component } from 'react';
import Todo from '../Todo/Todo';
import DayHeader from './DayHeader';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';

class DayContainer extends Component {

  constructor(props){
    super(props);
    this.todoClick = this.todoClick.bind(this);
    this.dayHeaderClick = this.dayHeaderClick.bind(this);
    this.dayContainerClick = this.dayContainerClick.bind(this);
  }

  todoClick(todo){
    this.props.todoClick(todo);
  }

  dayHeaderClick(date){
    this.props.dayHeaderClick(date);
  }

  dayContainerClick(event){
    event.stopPropagation();
    this.props.dayContainerClick(this.props.date);
  }

  render() {
    const containsTodosFromThePast = this.props.date === "Past";

    const sortedTodoList = this.props.todos.sort((a, b) => {
       return a.position > b.position ? 1 : -1;
    });

    const todoComponentList = sortedTodoList.map((todo) => {
      return <Todo
                selectedTodo={this.props.selectedTodo}
                key={todo._id}
                todo={todo}
                dateOfDayContainer={this.props.date}
                editTodo={this.props.editTodo}
                completeTodo={this.props.completeTodo}
                todoClick={this.todoClick}
                position={todo.position}
                />
    });

    return(
    <Container
      style={containsTodosFromThePast ? {opacity: 0.35} : {}}
      fluid={true}
      className="DayContainer"
      data-date={this.props.date}
      onClick={containsTodosFromThePast ? ()=>{} : this.dayContainerClick}
    >
      <DayHeader
        date={this.props.date}
        selectedTodo={this.props.selectedTodo}
        dayHeaderClick={containsTodosFromThePast ? ()=>{} : this.dayHeaderClick}
        custom={this.props.custom}
        removeCustomContainer={this.props.removeCustomContainer}
        toggleHiddenCustomContainer={this.props.toggleHiddenCustomContainer}
        hidden={this.props.hidden}
      />
      <Row style={styles.todoList} hidden={this.props.hidden ? true : false}>
        {todoComponentList}
      </Row>
    </Container>
    )
  }

}

const styles = {
  todoList: {
    minHeight: 42,
    background: "lightgrey"
  }
}

export default DayContainer;


export function getFirstFreeTodoPositionOfDayContainer(dateOfDayContainer){
  try {
    const todosInDayContainer =
      document.querySelector(`.DayContainer[data-date='${dateOfDayContainer}']`)
      .querySelectorAll(".Todo");
    let lastTodoInDayContainer;
    for(let i = todosInDayContainer.length-1; i >= 0 ; i--){
      lastTodoInDayContainer  = todosInDayContainer[i];
      let position = parseInt(lastTodoInDayContainer.getAttribute("data-position")) + 1;
      if(position && position < 999){
        return position;
      }
    }
    return 0;
  } catch (e) {
    console.log(e);
    return 500;
  }
}

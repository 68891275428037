import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

class RemoveCustomContainerButton extends Component {

  constructor(props){
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event){
    event.stopPropagation();
    this.props.removeCustomContainer(this.props.name);
  }

  render() {
    return(
      <div style={styles.buttonContainer} onClick={this.handleClick}>
        <FontAwesomeIcon icon={faTrash} style={styles.removeCustomContainerButton}/>
      </div>
    )
  }
}

const styles = {
  buttonContainer: {
    display: "inline-block",
    height: 35,
    width: 25
  },
  removeCustomContainerButton: {
    height: 18,
    width: 18,
    transform: "translateY(5px)",
    marginRight: 5
  }
}


export default RemoveCustomContainerButton;

import PouchDB from 'pouchdb'
import moment from 'moment'
import {pouchCredentials} from '../../components/config/config'
import customAlert from '../Other/Alerts/CustomAlert'
import { isTodoReoccuring, isCopyOfReoccuring } from '../MainView/TodoView/Todo/Todo'

var crypto = require('crypto')

const DEFAULT_USER_SECRET = 'defaultSecret-l13ßfüaäcva35qsacfasdfaö'

export default class Pouch {

  constructor (user) {
    window.pouch = this

    this.user = user
    // Use default encryption secret for users that signed up using their email address. Otherwise use unique id of provider
    this.userSecret = user.providerData[0].providerId !== 'password' ? user.providerData[0].uid : DEFAULT_USER_SECRET
    this.db = new PouchDB(`todos-${user.uid.toLowerCase()}`)
    this.remoteCouch = pouchCredentials.url + `/todos-${user.uid.toLowerCase()}`

    this.initUserDataIfNotAlreadyInitialized()
    this.sync()

    this.getAllTodosDecryptThemAndPushThemBackToDB()

  }

  encryptTodo (todo) {
    // todo.text = this.encrypt(todo.text)
  }

  decryptTodo (todo) {
    todo.text = this.decrypt(todo.text)
  }

  encrypt (property) {
    const key = crypto.createCipher('aes-128-cbc', this.userSecret)
    let encrypted = key.update(property, 'utf8', 'hex')
    encrypted += key.final('hex')
    return encrypted
  }

  decrypt (property, decryptKey = null, retry = true) {
    try {
      const key = crypto.createDecipher('aes-128-cbc', decryptKey || this.userSecret)
      let decrypted = key.update(property, 'hex', 'utf8')
      decrypted += key.final('utf8')
      return decrypted
    } catch (e) {
      if (retry) return this.decrypt(property, DEFAULT_USER_SECRET, false) // HOTFIX: when users are authenticated using google.com AND Email, decryption may fail
      else return property
      // else return 'Todo could not be decrypted. Please contact support!'
    }
  }

  async addTodo (todo) {
    this.encryptTodo(todo)
    delete todo.dateOfDayContainer // Remove this temporary property before storing in database
    todo._id = new Date().toISOString()
    todo.completed = false
    todo.lastEdit = new Date().getTime()

    if (!isTodoReoccuring(todo)) {
      return this.db.put(todo, function callback (err, result) {
        if (!err) {
          return result
        }
      })
    } else {
      return await this.addReoccuringTodo(todo)
    }
  }

  async addReoccuringTodo (todo) {
    // always add reoccuring Todos to the end of the Container
    const newPosition = await this.getHighestPositionValueOfAllTodos() + 1
    todo.position = newPosition

    let addAmount
    let maxItems
    switch (todo.reoccuring) {
      case 'Daily':
        addAmount = 'days'
        maxItems = 5000
        break
      case 'Weekly':
        addAmount = 'weeks'
        maxItems = 700
        break
      case 'Monthly':
        addAmount = 'months'
        maxItems = 160
        break
      case 'Yearly':
        addAmount = 'years'
        maxItems = 13
        break
      case 'Custom':
        addAmount = 'days'
        maxItems = 2000
        break
      default:
        maxItems = 5000
        addAmount = 'weeks'
    }

    const initialDueAt = todo.dueAt
    todo.dueAt = []

    for (let i = 0; i < maxItems; i++) {
      const nextDueAtInterval = todo.customReoccuring.repeatValue ? i * todo.customReoccuring.repeatValue : i
      addAmount = todo.customReoccuring.repeatType ? todo.customReoccuring.repeatType : addAmount
      let nextDueAt = moment(initialDueAt).add(nextDueAtInterval, addAmount).format('YYYY-MM-DD')
      todo.dueAt.push(nextDueAt)
    }

    return this.db.put(todo, function callback (err, result) {
      if (!err) {
        return result
      }
    })
  }

  editTodo (todo) {
    delete todo.dateOfDayContainer // Remove this temporary property before storing in database

    const instance = this
    const dbInstance = this.db
    return dbInstance.get(todo._id).then(function (doc) {
      doc.text = todo.text
      doc.dueAt = todo.dueAt
      doc.color = todo.color
      doc.position = todo.position
      doc.reoccuring = todo.reoccuring
      doc.completed = todo.completed
      doc.lastEdit = new Date().getTime()

      instance.encryptTodo(doc) // Encrypt again right before pushing to database

      return dbInstance.put(doc)
    }).then(function () {
      return dbInstance.get(todo._id)
    }).then(function (doc) {
      return doc
    })
  }

  async getTodos (completed = false) {
    let todos = []

    await this.db.allDocs({include_docs: true, descending: true}, (err, allDocs) => {
      if (err) {
        customAlert('An error occured: ' + err.code, err.message)
      } else {
        allDocs = this.filterOutUserData(allDocs)
        todos = allDocs.map((row) => {
          this.decryptTodo(row.doc) // Decrypt before returning
          return row.doc
        })
      }
    })

    return todos.filter((todo) => {
      return completed ? todo.completed : !todo.completed
    })
  }

  async removeTodo (todo) {
    if (isTodoReoccuring(todo)) {
      await this.removeAllReferencedCopiesOfReoccuringTodo(todo)
    }
    await this.db.remove(todo)
  }

  async completeTodo (todo) {
    todo.completed = true
    await this.editTodo(todo)
  }

  async restoreTodo (todo) {
    todo.completed = false
    await this.editTodo(todo)
  }

  async getReoccuringTodos () {
    const todos = await this.getTodos()
    return todos.filter((todo) => {
      return isTodoReoccuring(todo)
    })
  }

  async getRegularTodos (completed = false) {
    const todos = await this.getTodos(completed)
    return todos.filter((todo) => {
      return !isTodoReoccuring(todo) && !isCopyOfReoccuring(todo)
    })
  }

  async getHighestPositionValueOfAllTodos () {
    const todos = await this.getTodos()
    let highestPositionValueOfAllTodos = Math.max.apply(Math, todos.map(function (todo) { return todo.position }))
    return highestPositionValueOfAllTodos >= 0 ? highestPositionValueOfAllTodos : 0
  }

  async getUncompletedTodosByDueDate (dueAt) {
    const todos = await this.getTodos()
    return todos.filter((todo) => {
      return todo.dueAt === dueAt
    })
  }

  async removeAllReferencedCopiesOfReoccuringTodo (reoccuringTodo) {
    const allTodos = await this.getTodos()
    let allCopiesOfReoccuringTodos = []

    allCopiesOfReoccuringTodos = allTodos.filter((todo) => {
      return todo.reoccuringTodoReferenceId === reoccuringTodo._id
    })

    allCopiesOfReoccuringTodos.map((todo) => {
      return todo._deleted = true
    })

    // Deleting Documents
    this.db.bulkDocs(allCopiesOfReoccuringTodos, function (err, response) {
      if (err) {
        return console.log(err)
      } else {
        console.log(response + ' Documents deleted Successfully')
      }
    })
  }

  async updateMany (todos) {
    // We need to clone the todos array  without reference to the original todos so that the encryption will not be reflected on the UI
    const clonedTodos = JSON.parse(JSON.stringify(todos))
    clonedTodos.forEach((todo) => {
      return this.encryptTodo(todo)
    })

    return await this.db.bulkDocs(clonedTodos, function (err, response) {
      if (err) {
        return console.log(err)
      } else {
        console.log(JSON.stringify(response) + ' Documents updated Successfully')
        return response
      }
    })
  }

  sync () {
    // syncDom.setAttribute('data-sync-state', 'syncing');
    var opts = {live: true, retry: true}
    this.db.replicate.to(this.remoteCouch, opts, this.syncError)
    this.db.replicate.from(this.remoteCouch, opts, this.syncError)
  }

  syncError (e) {
    console.log('Error while syncing DB', e)
  }

  refreshFromServer () {
    PouchDB.replicate(this.remoteCouch, this.db)
  }

  // USER DATA SECTION

  initUserDataIfNotAlreadyInitialized () {
    this.db.get('userData').then((doc) => {
      // If it already exists, do nothing...
    }).catch((err) => {
      if (err.status === 404) {
        this.initUserData()
      }
    })
  }

  async initUserData () {
    const userData = {
      _id: 'userData',
      customContainers: []
    }
    this.putUserData(userData)
  }

  filterOutUserData (docs) {
    return docs.rows.filter((row) => {
      return row.id !== 'userData'
    })
  }

  async getUserData () {
    return this.db.get('userData').then((doc) => {
      return doc
    }).catch((err) => {
      console.log(err)
    })
  }

  putUserData (userData) {
    this.db.put(userData, (err, result) => {
      if (err) {
        console.log(err)
      } else {
        return result
      }
    })
  }

  updateUserData (updatedUserData) {
    this.db.get(updatedUserData._id).then((doc) => {
      doc.customContainers = updatedUserData.customContainers
      this.putUserData(doc)
    })
  }


  //TODO REMOVE THIS FUNCTION FOR EVER ONCE IT HAS BEEN SERVED ITS PURPOSE 
  async getAllTodosDecryptThemAndPushThemBackToDB (completed = false) {
    let todos = []

    await this.db.allDocs({include_docs: true, descending: true}, (err, allDocs) => {
      if (err) {
        customAlert('An error occured: ' + err.code, err.message)
      } else {
        allDocs = this.filterOutUserData(allDocs)
        todos = allDocs.map((row) => {
          this.decryptTodo(row.doc) // Decrypt before returning
          return row.doc
        })
      }
    })

    todos = todos.filter((todo) => {
      return !todo.decrypted
    })

    todos.forEach((todo) =>{
      todo.decrypted = true
    })

    window.pouch.updateMany(todos)

    return todos

  }

}

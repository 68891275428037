import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class AboutView extends Component {

  render() {
    return (
      <Container style={styles.aboutView}>
        <Row>
          <Col md={12} lg={12}>
            <u><b>Hints</b></u>
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={12}>
            <div style={styles.hintContainer}>
              <ul style={styles.hintList}>
                <li style={styles.hint}>Click/tap a todo once, then click/tap somewhere else to <i>move it there</i></li>
                <li style={styles.hint}>When adding a new todo, use the <i>advanced options</i> to create a <i>reoccuring todo</i></li>
              </ul>
            </div>
          </Col>
        </Row>
        <br/>
        <br/>
        <Row>
          <Col md={12} lg={12}>
            For bug reports or suggestions please send an email to: <br/>
            <a href="mailto:weekmeapp@gmail.com?subject=Bug Reports and Suggestions">weekmeapp@gmail.com</a>
          </Col>
        </Row>
        <br/>
        <br/>
        <Row>
          <Col md={12} lg={12}>
            Developed by Jakub Müller 2019
          </Col>
        </Row>
      </Container>
    );
  }
}

const styles = {
  aboutView: {
    paddingTop: 25,
    color: "white",
    textAlign: "center"
  },
  hintContainer: {
    display: "inline-block",
    maxWidth: 385
  },
  hintList: {
    textAlign: "left",
  },
  hint: {
    margin: 10
  }
}

export default AboutView;

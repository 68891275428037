import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form'

import customAlert from '../Alerts/CustomAlert';

export default function openPrompt(title, handleClose, handleConfirm, maxLength) {
  var div = document.getElementById("prompt") || document.createElement('div');
  div.id = "prompt";
  ReactDOM.render(
     <Prompt container={div} title={title} handleClose={handleClose} handleConfirm={handleConfirm} maxLength={maxLength}/>,
     document.body.appendChild(div)
  );
}


class Prompt extends Component {

  constructor(props){
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);

    this.state={
      text: null
    }

  }

  handleChange(event){
    this.setState({
      text: event.target.value
    });
  }

  handleClose(){
    this.props.handleClose();
    ReactDOM.unmountComponentAtNode(this.props.container);
  }

  handleConfirm(){
    this.props.handleConfirm(this.state.text);
    ReactDOM.unmountComponentAtNode(this.props.container);
  }

  handleKeyPress(target) {
    if(target.charCode===13){
      this.handleConfirm();
    }
  }

  focusInput(){
    const input = document.getElementById("promptInput");
    if(input){
      input.focus();
    }
  }

  componentWillUnmount() {
   document.body.removeChild(this.props.container);
  }

  componentDidMount(prevProps, prevState) {
    this.focusInput();
  }

  render() {

    return (
        <Modal show={true} onHide={this.handleClose} backdrop="static" size="sm">

          <Container className="modalHeader">
            <Modal.Title style={styles.modalTitle}>{this.props.title}</Modal.Title>
            <button type="button" className="close" aria-label="Close" onClick={this.handleClose} style={styles.closeButton}>
              <span aria-hidden="true">&times;</span>
            </button>
          </Container>

          <Modal.Body style={styles.modalBody}>
            <Form.Control id="promptInput" type="text" placeholder="Enter a name for your Container" autofocus="true" onChange={this.handleChange} onKeyPress={this.handleKeyPress} maxlength={this.props.maxLength}/>
          </Modal.Body>

          <Modal.Footer>

            <Button
              style={styles.button}
              variant="secondary"
              onClick={this.handleClose}
            >
              Cancel
            </Button>
            <Button
              style={styles.button}
              variant="primary"
              onClick={this.handleConfirm}
            >
              Ok
            </Button>


          </Modal.Footer>

        </Modal>
    );
  }

}

const styles = {
  modalTitle: {
    display: "inline-block"
  },
  closeButton: {
    position: "absolute",
    right: 5,
    zIndex: 2000,
    width: 50,
    height: 50,
  },
  button: {
    width: "100%"
  },
}

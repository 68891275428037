import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Alert from 'react-bootstrap/Alert'

//Variants
  // 'primary',
  // 'secondary',
  // 'success',
  // 'danger',
  // 'warning',
  // 'info',
  // 'light',
  // 'dark',

export default function customAlert(title, text, variant="danger") {
  var div = document.getElementById("customAlertContainer") || document.createElement('div');
  div.id = "customAlertContainer";
  div.style.zIndex = 5000;
  div.style.position = "fixed";
  div.style.top = "0";
  div.style.left = "0";
  div.style.right = "0";
  div.style.marginLeft =  "auto";
  div.style.marginRight =  "auto";
  ReactDOM.render(
     <CustomAlert container={div} title={title} text={text} variant={variant}/>,
     document.body.appendChild(div)
  );
}

class CustomAlert extends Component {

  constructor(props){
    super(props);
    this.close = this.close.bind(this);
    this.componentWillUnmount = this.componentWillUnmount.bind(this);
  }

  close(){
    ReactDOM.unmountComponentAtNode(this.props.container);
  }

  componentWillUnmount() {
   document.body.removeChild(this.props.container);
  }

  render(){
    return (
      <Alert
        variant={this.props.variant}
        onClose={this.close}
        dismissible>
        <Alert.Heading>{this.props.title}</Alert.Heading>
        <p>
          {this.props.text}
        </p>
      </Alert>
    );
  }
}

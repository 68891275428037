import React, { Component } from 'react';

class ColorSelection extends Component {

  constructor(props){
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(){
    this.props.handleClick(this.props.color)
  }

  render() {

    const backgroundStyle = {
      background: this.props.color
    }

    let selectedStyle;
    if(this.props.color === this.props.selectedColor){
      selectedStyle = {
        boxShadow: "0 0 0 5px " + this.props.color,
      }
    }

    return(
      <div
        className="ColorSelection" style={{...styles.colorSelection, ...backgroundStyle, ...selectedStyle}}
        onClick={this.handleClick}
      >
      </div>
    )
  }

}

const styles = {
  colorSelection: {
    width: 40,
    height: 40,
    borderRadius: 5,
    border: "1px solid black",
    display: "inline-block",
    margin: "3px 5px 3px 5px",
    cursor: "pointer"
  }
}

export default ColorSelection;

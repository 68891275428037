import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';

class ToggleHiddenCustomContainerButton extends Component {

  constructor(props){
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event){
    event.stopPropagation();
    this.props.toggleHiddenCustomContainer(this.props.name);
  }

  render() {
    return(
      <div style={styles.buttonContainer} onClick={this.handleClick}>
        <FontAwesomeIcon icon={ this.props.hidden ? faEye : faEyeSlash} style={styles.toggleHiddenCustomContainerButton}/>
      </div>
    )
  }

}

const styles = {
  buttonContainer: {
    display: "inline-block",
    height: 35,
    width: 45
  },
  toggleHiddenCustomContainerButton: {
    height: 18,
    width: 18,
    transform: "translateY(5px)",
    marginRight: 10
  }
}

export default ToggleHiddenCustomContainerButton;

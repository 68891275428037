import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form'
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'

import ColorSelector from './ColorSelector';
import { getFirstFreeTodoPositionOfDayContainer } from '../DayContainer/DayContainer';
import { isTodoReoccuring } from './Todo';
import customAlert from '../../../Other/Alerts/CustomAlert';

export default function openTodoDialog(handleClose, selectedTodo, initialDate, pouch) {
  var div = document.getElementById("todoDialog") || document.createElement('div');
  div.id = "todoDialog";
  ReactDOM.render(
     <TodoDialog container={div} handleClose={handleClose} selectedTodo={selectedTodo} initialDate={initialDate} pouch={pouch}/>,
     document.body.appendChild(div)
  );
}


class TodoDialog extends Component {

  constructor(props){
    super(props);

    this.handleClose = this.handleClose.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.addToStack = this.addToStack.bind(this);
    this.onColorChange = this.onColorChange.bind(this);
    this.toggleAdvanced = this.toggleAdvanced.bind(this);
    this.hideAdvanced = this.hideAdvanced.bind(this);
    this.buildTodo = this.buildTodo.bind(this);
    this.buildPosition = this.buildPosition.bind(this);
    this.onChangeReoccuringOption = this.onChangeReoccuringOption.bind(this);


    this.state = {
      advanced: false,
      customReoccuring: false
    }
  }

  componentWillUnmount() {
   document.body.removeChild(this.props.container);
  }

  buildTodo(options){
    const text = document.getElementById("todoInput").value.trim();
    const dueAt = this.buildDueAt(options);
    const position = this.buildPosition(dueAt);
    const reoccuring = document.getElementById("select_reoccuring") ? document.getElementById("select_reoccuring").value : "no";
    const dateOfDayContainer = this.props.selectedTodo ? this.props.selectedTodo.dateOfDayContainer : null;

    const customReoccuringInputValue = document.getElementById("input_custom_reoccuring") ? document.getElementById("input_custom_reoccuring").value : null;
    const customReoccuringSelectValue = document.getElementById("select_custom_reoccuring") ? document.getElementById("select_custom_reoccuring").value : null;
    const customReoccuring = {
      repeatValue: customReoccuringInputValue,
      repeatType: customReoccuringSelectValue
    }

    return {
      _id: this.props.selectedTodo ? this.props.selectedTodo._id : null,
      text,
      dueAt,
      color: this.selectedColor,
      position,
      reoccuring,
      customReoccuring,
      dateOfDayContainer
    }
  }

  buildDueAt(options){
    if(this.shouldBeAddedToCustomContainer()) return this.props.initialDate;
    if(this.shouldBeAddedToStack(options)) return "Stack";
    else return document.getElementById("dateInput").value;
  }

  shouldBeAddedToStack(options){
    const dateInputValue = document.getElementById("dateInput").value;
    return (!dateInputValue || options.addToStack || moment(dateInputValue).isBefore(moment().format("YYYY-MM-DD")));
  }

  shouldBeAddedToCustomContainer(){
    return (this.props.initialDate !== "Stack" && !moment(this.props.initialDate).isValid());
  }

  buildPosition(dueAt){
    return this.props.selectedTodo && this.props.selectedTodo.dueAt === dueAt ? //Keep position when dueAt is not modified
           this.props.selectedTodo.position :
           getFirstFreeTodoPositionOfDayContainer(dueAt); //When dueAt is modified || a new todo is created --> get first free position
  }

  async saveTodo(todo){
    if(todo._id){ //Editing exisiting todo
      await this.props.pouch.editTodo(todo);
    } else { //Adding a new todo
      await this.props.pouch.addTodo(todo);
    }
  }

  handleClose(){
    this.props.handleClose();
    ReactDOM.unmountComponentAtNode(this.props.container);
  }

  async handleConfirm(options = {addToStack: false}){
    const text = document.getElementById("todoInput").value.trim();
    if(!text) return customAlert("Invalid Description", "Please provide a description for your todo");

    const todo = this.buildTodo(options);

    if(isTodoReoccuring(todo)){
      if(todo.dueAt === "Stack") return customAlert("Invalid Date", "Reoccuring todos need to have a valid date and cannot be added to the stack.");
      if(todo.reoccuring === "Custom"){
        if( (typeof parseInt(todo.customReoccuring) !== 'number') || todo.customReoccuring < 1 || todo.customReoccuring > 999) return customAlert("Invalid Custom Intervall", "Please input a number between 1 and 999");
      }
    }
    await this.saveTodo(todo);
    this.handleClose();
  }

  handleKeyPress(target) {
    if(target.charCode===13){
      this.handleConfirm();
    }
  }

  addToStack(){
    this.handleConfirm({addToStack: true});
  }

  onColorChange(color){
    this.newColor = color;
    this.forceUpdate();
  }

  toggleAdvanced(){
    this.setState({
      advanced: !this.state.advanced
    });
  }

  hideAdvanced(){
    this.setState({
      advanced: false
    });
  }

  onChangeReoccuringOption(event){
    this.setState({
      customReoccuring: event.target.value === "Custom"
    });
  }

  render() {
    this.selectedColor = this.newColor ? this.newColor : this.props.selectedTodo ? this.props.selectedTodo.color : "#FFFFCC";

    let rowReoccuring;

    if(!this.props.selectedTodo && this.state.advanced){
      rowReoccuring =
            <Row>
              <Col md={12} lg={12} style={styles.col}>
                <Form>
                  <Form.Group controlId="select_reoccuring">
                     <Form.Label>Reoccuring</Form.Label>
                     <Form.Control as="select" onChange={this.onChangeReoccuringOption}>
                       <option>no</option>
                       <option>Daily</option>
                       <option>Weekly</option>
                       <option>Monthly</option>
                       <option>Yearly</option>
                       <option>Custom</option>
                     </Form.Control>
                   </Form.Group>
                </Form>
              </Col>
            </Row>;
    }

    let advancedButton;
    if(!this.props.selectedTodo){ //Show advanced options only for new todos
      advancedButton =
                  <Row style={styles.row}>
                    <Col md={12} lg={12} style={styles.col} onClick={this.toggleAdvanced}>
                      <span style={styles.advancedButton}>Advanced Options <FontAwesomeIcon icon={ this.state.advanced ? faCaretUp : faCaretDown} style={{...styles.reoccuringIcon}} /></span>
                    </Col>
                  </Row>;
    }

    let rowCustomReoccuring;
    if(this.state.customReoccuring){
      rowCustomReoccuring =
        <Row style={styles.row}>
          <Col md={12} lg={12} style={styles.col}>
            Reoccur every
            <Form.Control
              id="input_custom_reoccuring"
              type="number"
              min={1}
              max={999}
              defaultValue={1}
              autoFocus={true}
              style={styles.customReoccuringInput}
            />
            <Form.Control as="select" id="select_custom_reoccuring" style={styles.customReoccuringSelect}>
              <option>Days</option>
              <option>Weeks</option>
              <option>Months</option>
              <option>Years</option>
            </Form.Control>
          </Col>
        </Row>
    }

    return (
        <Modal show={true} onHide={this.handleClose} backdrop="static" size="md">

          <Container className="modalHeader">

            <button type="button" className="close" aria-label="Close" onClick={this.handleClose} style={styles.closeButton}>
              <span aria-hidden="true">&times;</span>
            </button>

            <Row>
              <Col md={12} lg={4} style={styles.col}>
                <Modal.Title style={styles.modalTitle}>{this.props.selectedTodo ? "Edit Todo" : "New Todo"}</Modal.Title>
              </Col>
              <Col md={12} lg={8} style={styles.col}>
                <ColorSelector
                  selectedColor={this.selectedColor}
                  onColorChange={this.onColorChange}
                />
              </Col>
            </Row>

          </Container>

          <Modal.Body style={styles.modalBody}>
            <textarea
              id="todoInput"
              defaultValue={this.props.selectedTodo ? this.props.selectedTodo.text : ""}
              style={styles.textarea}
              rows="4" maxLength="180"
              placeholder="Todo description..."
              onKeyPress={this.handleKeyPress}
              autoFocus={true}
            />
          </Modal.Body>

          <Container className="modalFooter">
            <Row style={styles.row}>
              <Col md={12} lg={4} style={styles.col}>
                <input
                  id="dateInput"
                  type="date"
                  defaultValue={
                    this.props.initialDate
                  }
                  min={moment().format("YYYY-MM-DD")}
                  style={styles.dateInput}
                />
              </Col>
              <Col md={12} lg={4} style={styles.col}>
                <Button className="btn btn-primary" style={{...styles.dialogButton, ...styles.confirmButton}} variant="primary" onClick={this.handleConfirm}>
                  Confirm Date
                </Button>
              </Col>
              <Col md={12} lg={4} style={styles.col}>
                <Button className="btn btn-secondary" style={{...styles.dialogButton, ...styles.addToStackButton}} onClick={this.addToStack}>Add to Stack</Button>
              </Col>
            </Row>

            {advancedButton}

            {rowReoccuring}

            {rowCustomReoccuring}



          </Container>
        </Modal>
    );
  }

  componentDidMount(prevProps, prevState) {
    this.focusTextarea();
  }

  focusTextarea(){
    const textarea = document.getElementById("todoInput");
    if(textarea){
     const textareaValue = textarea.value;
      textarea.value = "";
      textarea.focus();
      textarea.value = textareaValue;
    }
  }

}

const styles = {
  textarea: {
    width: '100%'
  },
  datepicker: {

  },
  dateInput: {
    width: '100%',
    textAlign: "center",
    height: 40
  },
  dialogButton: {
    width: "100%",
    height: 40
  },
  confirmButton: {
    marginBottom: 1
  },
  addToStackButton: {
    marginBottom: 1
  },
  advancedButton: {
    cursor: "pointer"
  },
  closeButton: {
    position: "absolute",
    right: 5,
    zIndex: 2000,
    width: 50,
    height: 50,
  },
  modalTitle: {
    display: "inline-block"
  },
  row: {
    paddingLeft: 13,
    paddingRight: 13,
    paddingBottom: 5
  },
  col: {
    paddingTop: 5,
    paddingBottom: 0,
    paddingLeft: 2,
    paddingRight: 2,
    textAlign: "center"
  },
  modalBody: {
    paddingTop: 0,
    paddingBottom: 0
  },
  customReoccuringInput: {
    width: 85,
    height: 35,
    display: "inline-block",
    marginLeft: 10,
  },
  customReoccuringSelect: {
    width: 85,
    height: 35,
    display: "inline-block",
    marginLeft: 5,
  }
}

import React, { Component } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare } from '@fortawesome/free-regular-svg-icons';
import Row from 'react-bootstrap/Row';
import CustomContainerButtons from './CustomContainerButtons/CustomContainerButtons';


import dropHere from '../../../../assets/img/drop_here.png';

class DayHeader extends Component {

  constructor(props){
    super(props);
    this.dayHeaderClick = this.dayHeaderClick.bind(this);
  }

  dayHeaderClick(event){
    this.props.dayHeaderClick(this.props.date);
    if(!this.props.selectedTodo){
      event.stopPropagation();
    }
  }

  getDayString(date){
    if(date === "Stack") return date;
    else if(date === "Past") return "Unfinished";
    else if (moment(date).isValid() && !this.props.custom) return moment(date).format('dddd');
    else return date;
  }

  getDateString(date){
    if(date === "Stack" || !date) return "No date assigned";
    else if(date === "Past") return "Due before today";
    else if (moment(date).isValid() && !this.props.custom) return moment(date).format("Do MMMM");
    else return "";
  }

  render() {

    let dayHeaderButtonIcon;
    let cursorStyle = {cursor: "pointer"};
    if(this.props.date === "Past"){
      dayHeaderButtonIcon = null;
      cursorStyle = {cursor: "default"}
    }
    else if(this.props.selectedTodo){
      dayHeaderButtonIcon =
        <img
          className="shake"
          src={dropHere}
          alt="Drop Here"
          style={styles.dropHereIcon}
        />;
    } else if(!this.props.selectedTodo){
      dayHeaderButtonIcon = <FontAwesomeIcon icon={faPlusSquare} style={styles.addTodoIcon}/>;
    }

    let noDateAssignedStringStyle;
    if(this.props.date === "Stack" || !this.props.date){
      noDateAssignedStringStyle = {fontSize: 14};
    }

    let customContainerButtons = null;
    if(this.props.custom){
      customContainerButtons = <CustomContainerButtons
                                name={this.props.date}
                                removeCustomContainer={this.props.removeCustomContainer}
                                toggleHiddenCustomContainer={this.props.toggleHiddenCustomContainer}
                                hidden={this.props.hidden}/>
    }

    return(
      <Row className="DayHeader" style={{...styles.dayHeader, ...cursorStyle}} onClick={this.dayHeaderClick}>
        <span style={styles.dayString}>{this.getDayString(this.props.date)}</span>
        {dayHeaderButtonIcon}
        <span style={{...styles.dateString, ...noDateAssignedStringStyle}}>{this.getDateString(this.props.date)}</span>
        {customContainerButtons}
      </Row>
    )
  }

}

const styles = {
  dayHeader: {
      background: "grey",
      textAlign: "center",
      height: 35,
      boxShadow: "inset 0 0 5px #000000",
  },
  dayString: {
    fontSize: 22,
    position: "absolute",
    left: 10
  },
  dateString: {
    fontSize: 16,
    lineHeight: 2,
    position: "absolute",
    right: 10
  },
  dropHereIcon: {
    position: "absolute",
    marginLeft: "auto",
    marginRight: "auto",
    left: 0,
    right: 0,
    marginTop: 5,
    marginBottom: 5,
    width: 25,
    height: 25,
    opacity: "0.3",
  },
  addTodoIcon: {
    position: "absolute",
    marginLeft: "auto",
    marginRight: "auto",
    left: 0,
    right: 0,
    marginTop: 8,
    marginBottom: 8,
    fontSize: 18,
    opacity: "0.4",
  }
}

export default DayHeader;

import React, { Component } from 'react';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faCaretSquareRight, faCaretSquareLeft, faHistory, faSignOutAlt, faTasks, faUser, faCoffee, faInfoCircle} from '@fortawesome/free-solid-svg-icons'
import { faClock } from '@fortawesome/free-regular-svg-icons'
import addTodo from '../../../assets/img/add_todo_white.svg';

class WeekMeHeader extends Component {

  constructor(props){
    super(props);
    this.logout = this.logout.bind(this);
    this.showRegularTodos = this.showRegularTodos.bind(this);
    this.showReoccuringTodos = this.showReoccuringTodos.bind(this);
    this.showTodoView = this.showTodoView.bind(this);
    this.showAccountView = this.showAccountView.bind(this);
    this.showAboutView = this.showAboutView.bind(this);
    this.handleAddTodoClick = this.handleAddTodoClick.bind(this);
    this.incrementWeek = this.incrementWeek.bind(this);
    this.decrementWeek = this.decrementWeek.bind(this);
    this.resetWeek = this.resetWeek.bind(this);

    this.state = {
      decrementWeekButtonDisabled: true
    }
  }

  logout(){
    this.props.firebase.auth().signOut();
  }

  showRegularTodos(){
    this.props.showRegularTodos();
    this.setState({
      decrementWeekButtonDisabled: true
    });
  }

  showReoccuringTodos(){
    this.props.showReoccuringTodos();
    this.setState({
      decrementWeekButtonDisabled: true
    });
  }

  showTodoView(){
    this.props.showTodoView();
  }

  showAccountView(){
    this.props.showAccountView();
    this.setState({
      decrementWeekButtonDisabled: true
    });
  }

  showAboutView(){
    this.props.showAboutView();
    this.setState({
      decrementWeekButtonDisabled: true
    });
  }

  handleAddTodoClick(){
    this.props.showTodoView(() => {
      window.TodoView.addTodo();
    });
  }

  incrementWeek(){
    window.TodoView.incrementWeek();
    this.setState({
      decrementWeekButtonDisabled: false
    });
  }

  decrementWeek(){
    window.TodoView.decrementWeek();
    if(window.TodoView.state.advanceInWeeks <= 1){
      this.setState({
        decrementWeekButtonDisabled: true
      });
    }
  }

  resetWeek(){
    window.TodoView.resetWeek();
    this.setState({
      decrementWeekButtonDisabled: true
    });
  }

  buymeacoffee(){
    window.open("https://www.buymeacoffee.com/OnrcGkwmz");
  }


  render() {
    const weekButtonDisabledStyle = {opacity: 0.3, cursor: "default"};
    const insideTodoView = this.props.view === "TodoView" ? true : false;
    const decrementWeekButtonDisabledStyle = !insideTodoView || this.state.decrementWeekButtonDisabled ? weekButtonDisabledStyle : null;
    const incrementWeekButtonDisabledStyle = !insideTodoView ? weekButtonDisabledStyle : null;

    return(
      <div style={styles.weekMeHeader}>

        <span style={styles.weekText} onClick={this.resetWeek}>WeekMe</span>

        <img src={addTodo} alt="Add Todo" style={styles.addTodoButton} onClick={this.handleAddTodoClick}/>
        <FontAwesomeIcon icon={faCaretSquareLeft} onClick={this.decrementWeek} style={{...styles.weekChangeButton, ...decrementWeekButtonDisabledStyle}}/>
        <FontAwesomeIcon icon={faCaretSquareRight} onClick={this.incrementWeek} style={{...styles.weekChangeButton, ...incrementWeekButtonDisabledStyle}}/>

        <NavDropdown
          title={
            <FontAwesomeIcon icon={faBars} style={styles.bars}/>
          }
          alignRight
          style={styles.navDropdown}
        >
          <NavDropdown.Item style={styles.navDropdownItem} onClick={this.showTodoView}>
            <FontAwesomeIcon icon={faTasks}/><span style={styles.navDropdownText}>Todos by Week</span>
          </NavDropdown.Item>
          <NavDropdown.Item style={styles.navDropdownItem} onClick={this.showRegularTodos}>
            <FontAwesomeIcon icon={faClock}/><span style={styles.navDropdownText}>Regular Todos</span>
          </NavDropdown.Item>
          <NavDropdown.Item style={styles.navDropdownItem} onClick={this.showReoccuringTodos}>
            <FontAwesomeIcon icon={faHistory}/><span style={styles.navDropdownText}>Reoccuring Todos</span>
          </NavDropdown.Item>
          <NavDropdown.Item style={styles.navDropdownItem} onClick={this.showAccountView}>
            <FontAwesomeIcon icon={faUser} style={styles.faUser}/><span style={styles.navDropdownText}>Account</span>
          </NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item style={styles.navDropdownItem} onClick={this.showAboutView}>
            <FontAwesomeIcon icon={faInfoCircle} style={styles.faAboutCircle}/><span style={styles.navDropdownText}>About</span>
          </NavDropdown.Item>
          <NavDropdown.Item style={styles.navDropdownItem} onClick={this.buymeacoffee}>
              <FontAwesomeIcon icon={faCoffee} style={styles.faCoffee}/><span style={styles.navDropdownText}>Buy Me A Coffee</span>
          </NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item style={styles.navDropdownItem} onClick={this.logout}>
            <FontAwesomeIcon icon={faSignOutAlt}/><span style={styles.navDropdownText}>Logout</span>
          </NavDropdown.Item>
        </NavDropdown>
      </div>
    )
  }
}

const styles = {
  weekMeHeader: {
    background: "#211f1f",
    height: 45,
    textAlign: "center",
  },
  navDropdown: {
    position: "absolute",
    right: 0,
    top: 0,
  },
  navDropdownItem: {
    fontSize: 18
  },
  navDropdownText: {
    marginLeft: 11
  },
  addTodoButton: {
    position: "absolute",
    left: 0,
    width: 35,
    height: 35,
    margin: 5,
    opacity: 0.75,
    cursor: "pointer"
  },
  bars: {
    color: "white",
    fontSize: 28
  },
  weekChangeButton: {
    margin: "5px 55px",
    fontSize: 35,
    color: "white",
    opacity: 0.75,
    cursor: "pointer"
  },
  weekText: {
    color: "white",
    opacity: 0.75,
    fontSize: 25,
    fontFamily: "Courier New",
    position: "absolute",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 5,
    marginBottom: 5,
    left: 0,
    right: 0,
    width: 100,
    cursor: "pointer"
  },
  faUser: {
    marginLeft: 1
  },
  faCoffee: {
    marginLeft: -4
  },
  faAboutCircle: {
    marginLeft: -1
  }
}

export default WeekMeHeader;

import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';

import customAlert from '../../../Other/Alerts/CustomAlert';
import openPrompt from '../../../Other/Dialogs/Prompt';


class ColorSelector extends Component {

  constructor(props){
    super(props);
    this.propmtContainerName = this.propmtContainerName.bind(this);
  }

  propmtContainerName(){
    openPrompt("Add Container",
    () => {

    }, (text) => {
      if(!text) return customAlert("Invalid Name", "Containers cannot be added without a name.");
      this.props.addCustomContainer(text);
    }, 15);
    return;
  }


  render() {
    return(
      <div className="AddCustomContainer" style={styles.addCustomContainer}>
        <Button className="btn btn-secondary" onClick={this.propmtContainerName} style={styles.button}>Add Container</Button>
      </div>
    )
  }

}

const styles = {
  addCustomContainer: {
    textAlign: "center",
    marginTop: 5,
    marginBottom: 10
  },
  button: {
    margin: 5,
    opacity: 0.5
  }
}

export default ColorSelector;

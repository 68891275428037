import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';

import customAlert from '../Other/Alerts/CustomAlert';

import {
  faGoogle,
} from '@fortawesome/free-brands-svg-icons';

import { faUserSecret, faEnvelope } from '@fortawesome/free-solid-svg-icons';

class Login extends Component {

  constructor(props){
    super(props);
    this.state = {
      showLoginWithEmail: false,
      showSignUpWithEmail: false,
      showLoadingAnimation: true
    }

    this.handleLogin = this.handleLogin.bind(this);
    this.handleSignUp = this.handleSignUp.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.showSignUpWithEmail = this.showSignUpWithEmail.bind(this);
  }

  handleKeyPress(target) {
    if(target.charCode===13){
      this.handleLogin();
    }
  }

  handleLogin(){
    const email = document.getElementById("inputEmail").value;
    const password = document.getElementById("inputPassword").value;
    this.props.firebase.auth().signInWithEmailAndPassword(email, password).catch(function(err) {
      customAlert("An error occured: " + err.code, err.message);
    });
  }

  handleSignUp(){
    const email1 = document.getElementById("inputEmail1").value;
    const email2 = document.getElementById("inputEmail2").value;
    const password1 = document.getElementById("inputPassword1").value;
    const password2 = document.getElementById("inputPassword2").value;

    let emailsMatch = false;
    if(email1.toLowerCase() === email2.toLowerCase()){
      emailsMatch = true;
    } else {
      customAlert("Emails Addresses do not match", "The Emails Addresses you entered do not match. Please re-enter your new Email Address.");
    }

    let passwordsMatch = false;
    if(password1 === password2){
      passwordsMatch = true;
    } else {
      customAlert("Passwords do not match", "The passwords you entered do not match. Please re-enter your password.");
    }

    if(emailsMatch && passwordsMatch){
      this.props.firebase.auth().createUserWithEmailAndPassword(email1, password1).catch(function(err) {
        customAlert("An error occured: " + err.code, err.message);
      });
    }
  }

  resetPassword(){
    const email = document.getElementById("inputEmail").value;
    this.props.firebase.auth().sendPasswordResetEmail(email).then(function() {
      customAlert("Email delivered!", "An Email to reset your password has been sent to " + email, "success");
    }).catch(function(err) {
      customAlert("An error occured: " + err.code, err.message);
    });
  }

  componentDidMount(){
    this._ismounted = true;

    setTimeout(() => {
      if(this._ismounted){
        this.setState({
          showLoadingAnimation: false
        });
      }
    }, 2000);
  }

  componentWillUnmount() {
   this._ismounted = false;
  }

  showSignUpWithEmail(){
    this.setState({
      showLoginWithEmail: false,
      showSignUpWithEmail: true,
      showLoadingAnimation: false
    });
  }

  render() {


    if(this.state.showLoadingAnimation){
      return (
        <div style={{textAlign: "center", position: "relative"}}>
          <Spinner style={{width: "10rem", height: "10rem", marginTop: 200}} animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )
    }

    let loginWithEmail;
    if(this.state.showLoginWithEmail){
      loginWithEmail =
        <div id="loginWithEmail" style={styles.loginWithEmail}>
          <form>
            <input style={styles.input} id="inputEmail" type="email" placeholder="Email" autoComplete="username"/>
            <br/>
            <input style={styles.input} id="inputPassword" type="password" placeholder="Password" autoComplete="current-password" onKeyPress={this.handleKeyPress}/>
          </form>
          <button style={styles.loginWithEmailButton} className="btn btn-primary" onClick={this.handleLogin}>Login</button>
          <br/>
          <hr/>
          <span style={styles.pointer} className="text-primary" onClick={this.showSignUpWithEmail}>Sign Up for WeekMe</span>
          <br/>
          <hr/>
          <span style={styles.pointer} className="text-primary" onClick={this.resetPassword}>Reset Password</span>
          <hr/>
        </div>
    }

    let signUpWithEmail;
    if(this.state.showSignUpWithEmail){
      signUpWithEmail =
        <div id="signUpWithEmail" style={styles.loginWithEmail}>
          <form>
            <input style={styles.input} id="inputEmail1" type="email" placeholder="Email"/>
            <br/>
            <input style={styles.input} id="inputEmail2" type="email" placeholder="Confirm Email"/>
            <br/>
            <br/>
            <input style={styles.input} id="inputPassword1" type="password" placeholder="Password"/>
            <br/>
            <input style={styles.input} id="inputPassword2" type="password" placeholder="Confirm Password"/>
          </form>
          <button style={styles.loginWithEmailButton} className="btn btn-primary" onClick={this.handleSignUp}>Sign Up</button>
        </div>
    }

    return(
      <div className="Login" style={styles.login}>

      <Card style={styles.card} bg="light" text="black">
        <Card.Body>
          <Card.Title>Welcome to WeekMe</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">Login to get started</Card.Subtitle>

            <button
              className="btn"
              style={{...styles.loginButton, ...styles.googleLoginButton}}
              onClick={() => {
                const googleAuthProvider = new this.props.firebase.auth.GoogleAuthProvider();
                this.props.firebase.auth().signInWithRedirect(googleAuthProvider).then(() => {
                  //On success do nothing
                }).catch((err) => {
                  customAlert("An error occured: " + err.code, err.message);
                });
              }}
            >
              <FontAwesomeIcon icon={faGoogle}/>   Login With Google
            </button>

            <br/>

            {
            //
            // <button
            //   className="btn"
            //   style={{...styles.loginButton, ...styles.anonymousLoginButton}}
            //   data-testid="signin-anon"
            //   onClick={() => {
            //     this.props.firebase.auth().signInAnonymously();
            //   }}
            // >
            // <FontAwesomeIcon icon={faUserSecret}/>   Anonymous Login
            // </button>
            //
            // <br/>
            }

            <button
              className="btn"
              style={{...styles.loginButton, ...styles.emailLoginButton}}
              onClick={() => {
                this.setState({
                  showLoginWithEmail: true,
                  showSignUpWithEmail: false
                });
              }}
            >
            <FontAwesomeIcon icon={faEnvelope}/>   Login Using Email
            </button>

            <br/>

            {loginWithEmail}
            {signUpWithEmail}

          <Card.Link href="/impress" target="_blank">Impress</Card.Link>
          <Card.Link href="/privacy" target="_blank" >Privacy</Card.Link>
        </Card.Body>
      </Card>

      </div>
    )
  }

}

const styles = {
  login: {
    textAlign: "center"
  },
  card: {
    display: "inline-block",
    boxShadow: "inset 0 0 10px #211f1f",
    marginTop: "7em",
    minWidth: 325
  },
  loginButton: {
    display: "inline-block",
    width: 225,
    textAlign: "left",
    color: "white",
    margin: 1
  },
  googleLoginButton: {
    background: "#ea4335"
  },
  anonymousLoginButton: {
    background: "#657786"
  },
  emailLoginButton: {
    background: "#aab8c2"
  },
  loginWithEmail: {

  },
  loginWithEmailButton: {
    width: "50%",
    marginTop: 10
  },
  pointer: {
    cursor: "pointer"
  },
  input: {
    width: "100%"
  }
}

export default Login;

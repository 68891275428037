import React from 'react'

class Impress extends React.Component {
  render() {
    return (
      <div style={styles.impress}>
        <p>Jakub Müller</p>
        <p>Danneckerstraße 12</p>
        <p>10245 Berlin</p>
        <p>Contact: <a href="mailto:weekmeapp@gmail.com">weekmeapp@gmail.com</a></p>
      </div>
    );
  }
}

const styles = {
  impress: {
    color: "white",
    textAlign: "center",
    marginTop: 50
  },
}

export default Impress;

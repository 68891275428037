import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faCheck, faHistory, faSync } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import Col from 'react-bootstrap/Col';

class Todo extends Component {

  constructor(props){
    super(props);

    this.state = {
      selected: false
    }

    this.complete = this.complete.bind(this);
    this.edit = this.edit.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.isTodoSelected = this.isTodoSelected.bind(this);
    this.getDynamicFontSize = this.getDynamicFontSize.bind(this);

  }

  complete(event){
    event.stopPropagation();
    const todoToComplete = this.props.todo;
    todoToComplete.dateOfDayContainer = this.props.dateOfDayContainer;
    this.props.completeTodo(todoToComplete);
  }

  edit(event){
    event.stopPropagation();
    const todoToEdit = this.props.todo;
    todoToEdit.dateOfDayContainer = this.props.dateOfDayContainer;
    this.props.editTodo(todoToEdit);
  }

  handleClick(event){
    if(this.props.selectedTodo && this.props.selectedTodo.dateOfDayContainer !== this.props.dateOfDayContainer){
      //Do not stopPropagation --> will trigger dayContainerClick --> will move the selectedTodo to dayContainer of clickedTodo
    } else {
      event.stopPropagation();
      const clickedTodo = this.props.todo;
      clickedTodo.dateOfDayContainer = this.props.dateOfDayContainer;
      this.props.todoClick(clickedTodo);
    }
  }

  getColSizeXSByTextLength(){
    if(this.props.todo.text.length < 12){
      return 6;
    } else {
      return 12;
    }
  }

  canTodoBePositionedHere(){
    return(
      this.props.selectedTodo &&
      this.props.selectedTodo !== this.props.todo &&
      this.props.dateOfDayContainer !== "Past" &&
      this.props.selectedTodo.dateOfDayContainer === this.props.dateOfDayContainer
    )
  }

  isTodoSelected(){
    return this.props.selectedTodo &&
           this.props.selectedTodo._id === this.props.todo._id &&
           this.props.selectedTodo.dateOfDayContainer === this.props.dateOfDayContainer;
  }

  getDynamicFontSize(){
    const textLength = this.props.todo.text.length;
    const fontSize =  18 - textLength / 35;
    return {fontSize};
  }

  render() {

    const isSelected = this.isTodoSelected();

    const colorStyle =  {background: this.props.todo.color};
    const selectedStyle =  isSelected ? Object.assign(styles.selected, {boxShadow: "0 0 0 5px " + this.props.todo.color}) : null;
    const canTodoBePositionedHereStyle =  this.canTodoBePositionedHere() ? {boxShadow: "0 0 0 2px white"} : null;

    const dynamicFontSize = this.getDynamicFontSize();
    const colSizeXS = this.getColSizeXSByTextLength();

    let buttons;
    let reoccuringIcon;

    if(isTodoReoccuring(this.props.todo) || isCopyOfReoccuring(this.props.todo)){
        reoccuringIcon = <FontAwesomeIcon icon={faHistory} style={{...styles.reoccuringIcon}} />
    }

    if(isSelected){

      let editButton;
      if(!isTodoReoccuring(this.props.todo) && !isCopyOfReoccuring(this.props.todo)){
        editButton = <FontAwesomeIcon icon={faPencilAlt} style={{...styles.button, ...styles.editButton}} onClick={this.edit}/>
      }

      buttons =
      <div style={styles.buttons}>
        <FontAwesomeIcon icon={faCheck} style={{...styles.button, ...styles.doneButton}} onClick={this.complete}/>
        {editButton}
        {reoccuringIcon}
      </div>
    } else {
      buttons =
        <div style={styles.buttons}>
          {reoccuringIcon}
        </div>
    }

    return(
      <Col
        style={styles.col}
        xs={colSizeXS}
        sm="auto"
      >
        <div
          className="Todo"
          data-position={this.props.todo.position}
          data-id={this.props.todo._id}
          style={{...styles.todo, ...colorStyle, ...selectedStyle, ...canTodoBePositionedHereStyle}}
          onClick={this.handleClick}
        >
          <span style={{...styles.text, ...dynamicFontSize}}>{this.props.todo.text}</span>
          {buttons}
        </div>
      </Col>
    )
  }
}

const styles = {
  text: {
    fontSize: 18, //Default, but will be overwritten by dynamicFontSize
  },
  col: {
    padding: 3
  },
  todo: {
    border: "1px solid black",
    borderRadius: 5,
    minWidth: 155,
    minHeight: 34,
    overflowWrap: "break-word",
    padding: "0.5em 5px",
    lineHeight: "1em",
    textAlign: "center",
    cursor: "pointer",
  },
  selected: {
    background: "white",
  },
  buttons: {
    position: "absolute",
    top: 4,
    left: 0,
    width: "100%",
    paddingLeft: 3,
    paddingRight: 3
  },
  button: {
    width: 55,
    height: 28,
    display: "inline-block",
    margin: 2,
    padding: 2,
    background: "rgb(211, 211, 211, 0.75)",
    borderRadius: 5
  },
  doneButton: {
    float: "right",
  },
  editButton: {
    float: "left",
  },
  reoccuringIcon: {
    float: "left",
    opacity: 0.25,
    marginLeft: 2,
    marginTop: 2,
    fontSize: 12
  },
}

export default Todo;


export function handleRemoveReoccuringDueDateEntries(todo, date){
  if(todo.dateOfDayContainer === "Past"){
    removeAllReoccuringEntriesThatLayInThePast(todo);
  } else {
    removeDateFromArrayOfDueDates(todo, date);
  }
}

export function removeDateFromArrayOfDueDates(todo, date){
  todo.dueAt = todo.dueAt.filter((dueAt) => {
    return dueAt !== date;
  });
}

export function removeAllReoccuringEntriesThatLayInThePast(todo){
  todo.dueAt = todo.dueAt.filter((dueAt) => {
    return moment().isBefore(moment(dueAt).add('days', 1));
  });
}

export function isTodoReoccuring(todo){
  return todo && todo.reoccuring !== "no";
}

export function isCopyOfReoccuring(todo){
  return todo && todo.isCopyOfReoccuring;
}

export function sortReoccuringTodos(todos){
  todos.sort((a, b) => {
    if(a.reoccuring === "Daily") return -1;
    if(a.reoccuring === "Yearly") return 1;
    if(a.reoccuring === "Weekly" && b.reoccuring === "Monthly") return -1;
    if(a.reoccuring === "Weekly" && b.reoccuring === "Yearly") return -1;
    if(a.reoccuring === "Weekly" && b.reoccuring === "Daily") return 1;
    if(a.reoccuring === "Monthly" && b.reoccuring === "Yearly") return -1;
    else return 0;
  });
  return todos;
}

export function sortRegularTodos(todos){

  const todosWithValidDueDate = [];
  const todosWithStringAsDueDate = [];

  todos.forEach((todo) => {
    if(moment(todo.dueAt).isValid()){
      todosWithValidDueDate.push(todo);
    } else {
      todosWithStringAsDueDate.push(todo);
    }
  })

  todosWithValidDueDate.sort((a, b) => {
    if(a.dueAt === b.dueAt){
      return a.position - b.position;
    }
    else return new Date(a.dueAt) - new Date(b.dueAt);
  });


  todosWithStringAsDueDate.sort(function(a, b) {

  //Sort Stack alway on Top
    if(a.dueAt === "Stack"){
      return -1;
    } else if(b.dueAt === "Stack"){
      return 1;
    }

  //Sort Todos in custom containers alphabetically
    else{
      const aDueAt = a.dueAt.toUpperCase();
      const bDueAt = b.dueAt.toUpperCase();
      return (aDueAt < bDueAt) ? -1 : (aDueAt > bDueAt) ? 1 : 0;
    }

  });


  todos = [...todosWithValidDueDate, ...todosWithStringAsDueDate];
  return todos;
}

export function sortByLastEdit(todos){
  todos.sort((a, b) => {
    return b.lastEdit - a.lastEdit;
  });
  return todos;
}

export function dueDateHasPassed(todo){
    const dueDate = isTodoReoccuring(todo) ? todo.dueAt[0] : todo.dueAt;
    const hasPassed = moment(dueDate).isBefore(moment().format("YYYY-MM-DD"));
    if(hasPassed) return true;
}

export function todoIsInsidePastContainer(todo){
  return todo.dateOfDayContainer === "Past";
}

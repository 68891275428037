import * as React from "react";
import { Component } from 'react';

import { render } from "react-dom";
import * as firebase from "firebase/app";
import "firebase/auth";
import {
  FirebaseAuthProvider,
  FirebaseAuthConsumer,
  IfFirebaseAuthed,
  IfFirebaseAuthedAnd
} from "@react-firebase/auth";
import {firebaseConfig} from "./components/config/config";

import MainView from './components/MainView/MainView';
import Login from './components/Login/Login';


class App extends Component {

  render(){
    return (
      <FirebaseAuthProvider {...firebaseConfig} firebase={firebase}>

      <FirebaseAuthConsumer>
        {({ isSignedIn, user, providerId }) => {

          console.log('isSignedIn:', isSignedIn)

          const view = isSignedIn ? <MainView user={user} firebase={firebase} providerId={providerId}/> : <Login firebase={firebase} user={user}/>;

          return (
            view
          );
        }}
      </FirebaseAuthConsumer>

          {
          // <div>
          //   <IfFirebaseAuthed>
          //     {() => {
          //       return <div>You are authenticated</div>;
          //     }}
          //   </IfFirebaseAuthed>
          //   <IfFirebaseAuthedAnd
          //     filter={({ providerId }) => providerId !== "anonymous"}
          //   >
          //     {({ providerId }) => {
          //       return <div>You are authenticated with {providerId}</div>;
          //     }}
          //   </IfFirebaseAuthedAnd>
          // </div>
          }

      </FirebaseAuthProvider>
    );
  }

};


export default App;

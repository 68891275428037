export const firebaseConfig = {
   apiKey: "AIzaSyBLcZ9Y9Q2I0u8Ik71tTVEz4QACO2bonjo",
   authDomain: "weekme-224015.firebaseapp.com",
   databaseURL: "https://weekme-224015.firebaseio.com",
   projectId: "weekme-224015",
   storageBucket: "",
   messagingSenderId: "723410786777",
   appId: "1:723410786777:web:eaccfec51b4a8f2f"
 };

 export const pouchCredentials = {
   "url": "https://13582485-1094-4ea0-9e85-4551bb858462-bluemix:6cf03be42b2f3447a488afd09d2359b59cd6b569fe0a5e7e4c55381d1d5b33e7@13582485-1094-4ea0-9e85-4551bb858462-bluemix.cloudantnosqldb.appdomain.cloud",
 }

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'


export default function openConfirmDialog(title, body, textApprove, textDeny, onDeny, onApprove, dangerousAction) {
  var div = document.getElementById("confirmDialog") || document.createElement('div');
  div.id = "confirmDialog";
  ReactDOM.render(
     <ConfirmDialog container={div} title={title} body={body} onDeny={onDeny} onApprove={onApprove} textApprove={textApprove} textDeny={textDeny} dangerousAction={dangerousAction}/>,
     document.body.appendChild(div)
  );
}


class ConfirmDialog extends Component {

  constructor(props){
    super(props);
    this.onDeny = this.onDeny.bind(this);
    this.onApprove = this.onApprove.bind(this);
  }

  onDeny(){
    this.props.onDeny();
    ReactDOM.unmountComponentAtNode(this.props.container);
  }

  onApprove(){
    this.props.onApprove();
    ReactDOM.unmountComponentAtNode(this.props.container);
  }

  componentWillUnmount() {
   document.body.removeChild(this.props.container);
  }

  render() {
    return(
      <Modal show={true} onHide={this.onDeny}>
        <Modal.Header>
          <Modal.Title>{this.props.title}</Modal.Title>
          <button type="button" className="close" aria-label="Close" onClick={this.onDeny} style={styles.closeButton}>
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>

        <Modal.Body>
          <p>{this.props.body}</p>
        </Modal.Body>

        <Modal.Footer>

        <Container>

          <Row>
            <Col xs={12} md={6} style={styles.col}>
              <Button
                style={styles.button}
                variant={this.props.dangerousAction ? "primary": "secondary"}
                onClick={this.onDeny}
              >
                {this.props.textDeny}
              </Button>
            </Col>
            <Col xs={12} md={6} style={styles.col}>
              <Button
                style={styles.button}
                variant={this.props.dangerousAction ? "secondary": "primary"}
                onClick={this.onApprove}
              >
                {this.props.textApprove}
              </Button>
            </Col>
          </Row>
        </Container>

        </Modal.Footer>
      </Modal>
  )
}

}

const styles = {
  confirmDialog: {

  },
  closeButton: {
    position: "absolute",
    right: 15,
    top: 10,
    zIndex: 2000,
    width: 50,
    height: 50,
  },
  col: {
    textAlign: "center"
  },
  button: {
    width: "100%",
    marginTop: 2,
    marginBottom: 2
  }
}

import React, { Component } from 'react';
import ColorSelection from './ColorSelection'

class ColorSelector extends Component {

  constructor(props){
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(color){
    this.props.onColorChange(color);
  }

  render() {
    return(
      <div className="ColorSelector" style={styles.colorSelector}>
        <ColorSelection color="#FFFFCC" selectedColor={this.props.selectedColor} handleClick={this.handleClick}/>
        <ColorSelection color="#DDFFFF" selectedColor={this.props.selectedColor} handleClick={this.handleClick}/>
        <ColorSelection color="#FFDDDD" selectedColor={this.props.selectedColor} handleClick={this.handleClick}/>
        <ColorSelection color="#DDFFDD" selectedColor={this.props.selectedColor} handleClick={this.handleClick}/>
      </div>
    )
  }

}

const styles = {
  colorSelector: {
    display: "inline-block"
  },
  palette: {
    fontSize: 25
  }
}

export default ColorSelector;

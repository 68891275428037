import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import openConfirmDialog from '../../Other/Dialogs/ConfirmDialog';
import customAlert from '../../Other/Alerts/CustomAlert';
import PouchDB from 'pouchdb';


class AccountView extends Component {

  constructor(props){
    super(props);
    this.deleteAccount = this.deleteAccount.bind(this);
    this.showDeleteAccountDialog = this.showDeleteAccountDialog.bind(this);
    this.reauthenticate = this.reauthenticate.bind(this);
    this.changeEmail = this.changeEmail.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.onPw1Change = this.onPw1Change.bind(this);
    this.onPw2Change = this.onPw2Change.bind(this);
    this.onEmail1Change = this.onEmail1Change.bind(this);
    this.onEmail2Change = this.onEmail2Change.bind(this);
    this.resetState = this.resetState.bind(this);

    this.state={
      pw1: "",
      pw2: "",
      email1: "",
      email2: ""
    }
  }

  async showDeleteAccountDialog(){
    try {
      await this.reauthenticate();

      openConfirmDialog("Are you sure?", "Deleting your account cannot be undone!", "Permanently Delete Account", "I want to keep using WeekMe",
      () => {
        //canceled
      }, () => {
        this.deleteAccount();
      }, true)

    } catch (err) {
      console.log(err);
      customAlert("An error occured: " + err.code, err.message);
    }
  }

  async reauthenticate(){
    const providerId = this.props.providerId;
    if(providerId === "password"){
      const password = window.prompt("Enter your password to proceed");
      const credential = this.props.firebase.auth.EmailAuthProvider.credential(
          this.props.user.email,
          password
      );
      await this.props.user.reauthenticateWithCredential(credential);
    } else {
      const provider = this.createProviderByProviderId(providerId);
      await this.props.user.reauthenticateWithPopup(provider);
    }
  }

  createProviderByProviderId(providerId){
    switch (providerId) {
      case "google.com":
        return new this.props.firebase.auth.GoogleAuthProvider();
      default:
        return null;
    }
  }

  async deleteAccount(){

    try {
      await this.props.user.delete();
    } catch (err) {
      console.log(err);
      customAlert("An error occured: " + err.code, err.message);
    }

    try {
      await new PouchDB(window.pouch.remoteCouch).destroy();
      this.resetState();
    } catch (err) {
      console.log(err);
      customAlert("An error occured: " + err.code, err.message);
    }

    customAlert("Your account has been deleted", "Your WeekMe account including all user data have been deleted from our servers. Thank you for using WeekMe!", "success");
  }

  async changePassword(){
    const pwNew1 = document.getElementById("inputPasswordNew").value;
    const pwNew2 = document.getElementById("inputPasswordNew2").value;

    if(pwNew1 === pwNew2){
      try {
        await this.reauthenticate();
        await this.props.user.updatePassword(pwNew1);
        this.resetState();
        customAlert("Your Password has been Updated", "", "success");
      } catch (err) {
        customAlert("An error occured: " + err.code, err.message);
      }
    } else{
      customAlert("Passwords do not match", "The passwords you entered do not match. Please re-enter your password.");
    }
  }

  async changeEmail(){
    const email1 = document.getElementById("inputEmail").value;
    const email2 = document.getElementById("inputEmail2").value;

    if(email1 === email2){
      try {
        await this.reauthenticate();
        await this.props.user.updateEmail(email1);
        this.resetState();
        customAlert("Your Email Address has been Updated", "", "success");
      } catch (err) {
        customAlert("An error occured: " + err.code, err.message);
      }
    } else{
      customAlert("Emails Addresses do not match", "The Emails Addresses you entered do not match. Please re-enter your new Email Address.");
    }
  }

  onPw1Change(event){
    this.setState({
      pw1: event.target.value
    });
  }

  onPw2Change(event){
    this.setState({
      pw2: event.target.value
    });
  }

  onEmail1Change(event){
    this.setState({
      email1: event.target.value
    });
  }

  onEmail2Change(event){
    this.setState({
      email2: event.target.value
    });
  }

  resetState(){
    this.setState({
      pw1: "",
      pw2: "",
      email1: "",
      email2: ""
    });
  }

  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  render() {
    let providerSpecificContent;
    if(this.props.providerId === "password"){
      providerSpecificContent =
        <div className="providerSpecificContent">
          <Row>
            <Col md={12} lg={12}>
              <input style={styles.input} id="inputPasswordNew" type="password" placeholder="New Password" onChange={this.onPw1Change}/>
            </Col>
            <Col md={12} lg={12}>
              <input style={styles.input} id="inputPasswordNew2" type="password" placeholder="Confirm new Password" onChange={this.onPw2Change}/>
            </Col>
            <Col md={12} lg={12}>
              <button
                disabled={this.state.pw1.length < 6 || this.state.pw2.length < 6}
                style={styles.input}
                className="btn btn-secondary"
                onClick={this.changePassword}>
                  Change Password
              </button>
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={12}>
              <input style={styles.input} id="inputEmail" type="email" placeholder="New Email" onChange={this.onEmail1Change}/>
            </Col>
            <Col md={12} lg={12}>
              <input style={styles.input} id="inputEmail2" type="email" placeholder="Confirm new Email" onChange={this.onEmail2Change}/>
            </Col>
            <Col md={12} lg={12}>
              <button
                disabled={!this.validateEmail(this.state.email1) || !this.validateEmail(this.state.email2)}
                style={styles.button}
                className="btn btn-secondary"
                onClick={this.changeEmail}>
                Change Email Address
              </button>
            </Col>
          </Row>
        </div>
    } else {
      providerSpecificContent =
        <Row>
          <Col md={12} lg={12}>
            <span style={styles.authenticatedUsing}> Authenticated using: {this.props.providerId}</span>
          </Col>
        </Row>
    }

    return(
    <Container className="AccountView" style={styles.accountView}>

      <Row>
        <Col md={12} lg={12}>
          <span style={styles.currentUserText}>{this.props.user.email}</span>
        </Col>
      </Row>

      {providerSpecificContent}

      <br/>

      <Row>
        <Col md={12} lg={12}>
          <button style={styles.button} className="btn btn-danger" onClick={this.showDeleteAccountDialog}>Delete Account</button>
        </Col>
      </Row>
    </Container>
  )
}

}

const styles = {
  accountView: {
    textAlign: "center",
    paddingTop: 45
  },
  currentUserText: {
    color: "white"
  },
  authenticatedUsing: {
    color: "white",
    fontSize: 12
  },
  button: {
    width: 300
  },
  input: {
    width: 300
  }
}

export default AccountView;

import React, { Component } from 'react';
import moment from 'moment';
import PullToRefresh from 'pulltorefreshjs';
import ReactDOMServer from 'react-dom/server';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSyncAlt, faLongArrowAltDown} from '@fortawesome/free-solid-svg-icons';

import Pouch from '../../DB/Pouch';
import openTodoDialog from './Todo/TodoDialog';
import openConfirmDialog from '../../Other/Dialogs/ConfirmDialog';

import DayContainer, { getFirstFreeTodoPositionOfDayContainer } from './DayContainer/DayContainer';
import AddCustomContainer from './AddCustomContainer/AddCustomContainer';
import { isTodoReoccuring, handleRemoveReoccuringDueDateEntries, removeAllReoccuringEntriesThatLayInThePast, dueDateHasPassed, todoIsInsidePastContainer } from './Todo/Todo';



class TodoView extends Component {

  constructor(props){
    super(props);

    window.TodoView = this;

    this.pouch = new Pouch(this.props.user);

    this.state = {
      todos: [],
      userData: null,
      selectedTodo: null,
      advanceInWeeks: 0,
    }

    this.getTodos = this.getTodos.bind(this);
    this.getUserData = this.getUserData.bind(this);
    this.addTodo = this.addTodo.bind(this);
    this.editTodo = this.editTodo.bind(this);
    this.completeTodo = this.completeTodo.bind(this);
    this.todoClick = this.todoClick.bind(this);
    this.dayHeaderClick = this.dayHeaderClick.bind(this);
    this.dayContainerClick = this.dayContainerClick.bind(this);
    this.todoViewClick = this.todoViewClick.bind(this);
    this.closeTodoDialoag = this.closeTodoDialoag.bind(this);
    this.buildDayContainers = this.buildDayContainers.bind(this);
    this.incrementWeek = this.incrementWeek.bind(this);
    this.decrementWeek = this.decrementWeek.bind(this);
    this.resetWeek = this.resetWeek.bind(this);
    this.doesTodoEqualSelectedTodo = this.doesTodoEqualSelectedTodo.bind(this);
    this.getAllTodosFromPast = this.getAllTodosFromPast.bind(this);
    this.shouldTodoClickDeselectTodo = this.shouldTodoClickDeselectTodo.bind(this);
    this.shouldSelectedTodoGoToPositionOfClickedTodo = this.shouldSelectedTodoGoToPositionOfClickedTodo.bind(this);
    this.createCopyOfReoccuringTodoAtTargetAndRemoveDueAtEntryFromSource = this.createCopyOfReoccuringTodoAtTargetAndRemoveDueAtEntryFromSource.bind(this);
    this.addCustomContainer = this.addCustomContainer.bind(this);
    this.removeCustomContainer = this.removeCustomContainer.bind(this);
    this.toggleHiddenCustomContainer = this.toggleHiddenCustomContainer.bind(this);
    this.showRemoveCustomContainerDialog = this.showRemoveCustomContainerDialog.bind(this);

    this.getTodosOnDBChange = this.getTodosOnDBChange.bind(this);
    this.getTodosOnDBChange(); //Make sure to bind to this before executing
  }

  todoViewClick(){
    if(!this.state.showTodoDialog){
      if(this.state.selectedTodo){
        this.setState({
          selectedTodo: null
        });
      }
    }
  }

  getTodosOnDBChange(){
    this.pouch.db.changes({
      since: 'now',
      live: true
    }).on('change', this.getTodos);
  }

  async getTodos(){
      const todos = await this.pouch.getTodos();
      this.setState({
          todos,
          selectedTodo: null
      });
  }

  async getUserData(){
    const userData = await this.pouch.getUserData();
    this.setState({
      userData
    })
  }

  addTodo(date){
    if(!date) date = moment().format("YYYY-MM-DD");
    openTodoDialog(this.closeTodoDialoag, null, date, this.pouch);
  }

  editTodo(todo){
    openTodoDialog(this.closeTodoDialoag, todo, todo.dueAt, this.pouch);
  }

  async completeTodo(todo){
    if(isTodoReoccuring(todo)){
      handleRemoveReoccuringDueDateEntries(todo, todo.dateOfDayContainer);
      await this.pouch.editTodo(todo);
    } else {
      this.pouch.completeTodo(todo);
    }
  }

  closeTodoDialoag(){
    this.setState({
      selectedTodo: null
    });
  }

  async todoClick(clickedTodo){
    if(this.shouldTodoClickDeselectTodo(clickedTodo)){
      this.setState({
        selectedTodo: null
      });
    } else {
      if(this.shouldSelectedTodoGoToPositionOfClickedTodo(clickedTodo)){
        this.moveSelectedTodoToPositionOfClickedTodo(clickedTodo);
      } else { //No todo is selected --> select clicked todo
        this.setState({
          selectedTodo: clickedTodo
        });
      }
    }
  }

  shouldTodoClickDeselectTodo(clickedTodo){
    return this.doesTodoEqualSelectedTodo(clickedTodo);
  }

  shouldSelectedTodoGoToPositionOfClickedTodo(clickedTodo){
    return this.state.selectedTodo &&
           !todoIsInsidePastContainer(clickedTodo) &&
           this.state.selectedTodo.dateOfDayContainer === clickedTodo.dateOfDayContainer
  }

  async moveSelectedTodoToPositionOfClickedTodo(clickedTodo){
    let selectedTodo = this.state.selectedTodo;

    if(isTodoReoccuring(clickedTodo)){
      let modifiedTodos = [...this.createCopyOfReoccuringTodoAtTargetAndRemoveDueAtEntryFromSource(clickedTodo, clickedTodo.dateOfDayContainer, clickedTodo.position)];
      await this.pouch.updateMany(modifiedTodos);
    }
    if(isTodoReoccuring(selectedTodo)){
      let modifiedTodos = [...this.createCopyOfReoccuringTodoAtTargetAndRemoveDueAtEntryFromSource(selectedTodo, selectedTodo.dateOfDayContainer, selectedTodo.position)];
      const response = await this.pouch.updateMany(modifiedTodos);
      selectedTodo = modifiedTodos[1]; //Select copyOfReoccuringTodo
      selectedTodo._id = response[1].id; //Assign id of response from server to copyOfReoccuringTodo
    }

    let todosToBeModified = await this.pouch.getUncompletedTodosByDueDate(clickedTodo.dateOfDayContainer);
    let modifiedTodos = [];

    for(let i = 0; i < todosToBeModified.length; i++){
      let currentTodo = JSON.parse(JSON.stringify(todosToBeModified[i]));
      if(currentTodo._id === selectedTodo._id) currentTodo.position = clickedTodo.position;
      else if(currentTodo.position >= clickedTodo.position && currentTodo.position <= selectedTodo.position) currentTodo.position++;
      modifiedTodos.push(currentTodo);
    }

    await this.pouch.updateMany(modifiedTodos);
  }

  async dayHeaderClick(date){
    const todo = this.state.selectedTodo;
    if(!todo){
      this.addTodo(date);
    }
  }

  async dayContainerClick(date){
    const todo = this.state.selectedTodo;
    if(todo){
      if(isTodoReoccuring(todo)){
        let modifiedTodos = [...this.createCopyOfReoccuringTodoAtTargetAndRemoveDueAtEntryFromSource(todo, date)];
        await this.pouch.updateMany(modifiedTodos);
      } else {
        todo.dueAt = date;
        todo.position = getFirstFreeTodoPositionOfDayContainer(date);

        console.log(todo);

        await this.pouch.editTodo(todo);
      }
      this.setState({
        selectedTodo: null
      });
    } else {
      this.setState({
        selectedTodo: null
      });
    }
  }

  createCopyOfReoccuringTodoAtTargetAndRemoveDueAtEntryFromSource(reoccuringTodo, targetDate, targetPosition = null){
    let modifiedTodos = [];

    if(targetPosition === null){
      targetPosition = getFirstFreeTodoPositionOfDayContainer(targetDate);
    }

    handleRemoveReoccuringDueDateEntries(reoccuringTodo, reoccuringTodo.dateOfDayContainer);
    modifiedTodos.push(reoccuringTodo);

    const copyOfReoccuringTodo = {
      text: reoccuringTodo.text,
      dueAt: targetDate,
      color: reoccuringTodo.color,
      position: targetPosition,
      reoccuring: "no",
      isCopyOfReoccuring: true,
      reoccuringTodoReferenceId: reoccuringTodo._id
    }

    modifiedTodos.push(copyOfReoccuringTodo);

    return modifiedTodos;
  }

  getAllTodosFromPast(){
    const todosFromPast = [];
    this.state.todos.forEach((todo) => {
      if(dueDateHasPassed(todo)) todosFromPast.push(todo);
    });
    return todosFromPast;
  }

  buildDayContainers(advanceInWeeks = 0){

    let dayContainerStartIndex;

    const todosFromPast = this.getAllTodosFromPast();

    if(todosFromPast.length === 0){
      dayContainerStartIndex = 0;
    } else {
      dayContainerStartIndex = -1;
    }

    let dayContainerList = [];
    for(let i = dayContainerStartIndex; i < 8; i++){

      let dateOfDayContainer;
      if(i === 7) dateOfDayContainer = "Stack";
      else if (i === -1) dateOfDayContainer = "Past";
      else dateOfDayContainer = moment().add(i, 'days').add(advanceInWeeks, 'weeks').format("YYYY-MM-DD");

      dayContainerList[i + Math.abs(dayContainerStartIndex)] =
        <DayContainer
          key={dateOfDayContainer}
          date={dateOfDayContainer}
          todos={this.filterTodosByDate(this.state.todos, dateOfDayContainer)}
          editTodo={this.editTodo}
          completeTodo={this.completeTodo}
          todoClick={this.todoClick}
          dayHeaderClick={this.dayHeaderClick}
          dayContainerClick={this.dayContainerClick}
          selectedTodo={this.state.selectedTodo}
          custom={false}
        />;
    }
    return dayContainerList;
  }

  buildCustomContainers(){

    console.log("STATE UPDATED TO OFTEN??", this.state);
    const customContainers = [];
    if(this.state.userData){
      this.state.userData.customContainers.forEach((customContainer) => {
        customContainers.push(this.buildCustomContainer(customContainer));
      })
      return customContainers;
    }
  }

  buildCustomContainer(customContainerData){

    return <DayContainer
      key={customContainerData.name}
      date={customContainerData.name}
      todos={this.filterTodosByDate(this.state.todos, customContainerData.name)}
      editTodo={this.editTodo}
      completeTodo={this.completeTodo}
      todoClick={this.todoClick}
      dayHeaderClick={this.dayHeaderClick}
      dayContainerClick={this.dayContainerClick}
      selectedTodo={this.state.selectedTodo}
      hidden={customContainerData.hidden}
      custom={true}
      removeCustomContainer={this.showRemoveCustomContainerDialog}
      toggleHiddenCustomContainer={this.toggleHiddenCustomContainer}
    />;

  }

  incrementWeek(){
    this.setState({
      advanceInWeeks: this.state.advanceInWeeks + 1
    })
  }

  decrementWeek(){
    if(this.state.advanceInWeeks > 0){
      this.setState({
        advanceInWeeks: this.state.advanceInWeeks -1
      })
    }
  }

  resetWeek(){
    this.setState({
      advanceInWeeks: 0
    })
  }

  filterTodosByDate(todos, date){
    return todos.filter((todo) => {
      if(date === "Stack"){
        if(todo.dueAt === "Stack" || Boolean(todo.dueAt) === false){
          return true;
        }
      } else if(date === "Past"){
          const dueDate = isTodoReoccuring(todo) ? todo.dueAt[0] : todo.dueAt;
          const fromPast = moment(dueDate).isBefore(moment().format("YYYY-MM-DD"));
          if(fromPast) return true;
      } else { //Not Stack
        if(isTodoReoccuring(todo)){
          return todo.dueAt.includes(date);
        } else {
          return todo.dueAt === date;
        }
      }
      return false; //Todo.dueAt does not match date
    });
  }

  doesTodoEqualSelectedTodo(todo){
    return this.state.selectedTodo && this.state.selectedTodo._id === todo._id;
  }

  getBodyScrollTop() {
     const el = document.scrollingElement || document.documentElement;
     return el.scrollTop;
   }

   shouldPullToRefresh(){
     return this.getBodyScrollTop() < 1;
   }

   onRefresh(){
    this.pouch.refreshFromServer();
   }

   addCustomContainer(name){
     let userData = this.state.userData;
     userData.customContainers.push({name, hidden: false});
     this.pouch.updateUserData(userData);
   }

   showRemoveCustomContainerDialog(containerName){

     openConfirmDialog(
       `Delete Container ${containerName}?`,
       "Do you really want to delete this container?",
       "Delete",
       "Keep",
     () => {
       //Denied
     }, () => {
       this.removeCustomContainer(containerName);
     });
   }

   removeCustomContainer(containerName){
     let userData = this.state.userData;
     userData.customContainers = userData.customContainers.filter(e => e.name !== containerName);
     this.pouch.updateUserData(userData);
   }

   toggleHiddenCustomContainer(name){
     let userData = this.state.userData;
     userData.customContainers = userData.customContainers.map((e) => {
       if(e.name === name){
         e.hidden = !e.hidden;
       }
       return e;
     });
     this.pouch.updateUserData(userData);
   }

  componentWillMount(){
    this.getTodos();
    this.getUserData();
  }

  componentDidMount(){
    const instance = this;
      PullToRefresh.init({
          mainElement: '.TodoView',
          onRefresh() {
            instance.onRefresh();
          },
          iconArrow: ReactDOMServer.renderToString(
              <FontAwesomeIcon icon={faLongArrowAltDown} />
          ),
          iconRefreshing: ReactDOMServer.renderToString(
              <FontAwesomeIcon icon={faSyncAlt} spin={true} />
          ),
          instructionsRefreshing: "Refreshing",

          distReload: 60,
          shouldPullToRefresh() {
            return instance.shouldPullToRefresh();
          }
      });
  }

  componentWillUnmount(){
    PullToRefresh.destroyAll();
  }

 render(){

    const dayContainers = this.buildDayContainers(this.state.advanceInWeeks);
    const customContainers = this.buildCustomContainers();

    return(
      <div className="TodoView" onClick={this.todoViewClick} >
        {dayContainers}
        {customContainers}
        <AddCustomContainer addCustomContainer={this.addCustomContainer}/>
      </div>
    )
  }
}

export default TodoView;

import React, { Component } from 'react';
import WeekMeHeader from './WeekMeHeader/WeekMeHeader';
import TodoView from './TodoView/TodoView';
import TodoListView from './TodoListView/TodoListView';
import AccountView from './AccountView/AccountView';
import AboutView from './AboutView/AboutView';


class MainView extends Component {

  constructor(props){
    super(props);
    this.showRegularTodos = this.showRegularTodos.bind(this);
    this.showReoccuringTodos = this.showReoccuringTodos.bind(this);
    this.showTodoView = this.showTodoView.bind(this);
    this.showAccountView = this.showAccountView.bind(this);
    this.showAboutView = this.showAboutView.bind(this);
    this.state = {
      view: "TodoView"
    }
  }

  showRegularTodos(){
    this.setState({
      view: "RegularTodoView"
    });
  }

  showReoccuringTodos(){
    this.setState({
      view: "ReoccuringTodoView"
    });
  }

  showTodoView(callback = () => {}){
    this.setState({
      view: "TodoView"
    }, () => {
      callback();
    });
  }

  showAccountView(){
    this.setState({
      view: "AccountView"
    });
  }

  showAboutView(){
    this.setState({
      view: "AboutView"
    });
  }

  render() {
    let view;
    if(this.state.view === "TodoView"){
      view = <TodoView user={this.props.user}/>;
    } else if(this.state.view === "RegularTodoView"){
      view = <TodoListView user={this.props.user} filter="regular" key="regular"/> //Use key to force rerender on prop change
    } else if(this.state.view === "ReoccuringTodoView"){
      view = <TodoListView user={this.props.user} filter="reoccuring" key="reoccuring"/> //Use key to force rerender on prop change
    } else if(this.state.view === "AccountView"){
      view = <AccountView user={this.props.user} firebase={this.props.firebase} providerId={this.props.providerId}/>
    } else if(this.state.view === "AboutView"){
      view = <AboutView/>
    }

    return(
    <div className="MainView">
      <WeekMeHeader
        firebase={this.props.firebase}
        showRegularTodos={this.showRegularTodos}
        showReoccuringTodos={this.showReoccuringTodos}
        showTodoView={this.showTodoView}
        showAccountView={this.showAccountView}
        showAboutView={this.showAboutView}
        view={this.state.view}/>
      {view}
    </div>
  )
}

}

export default MainView;

import React, { Component } from 'react';
import RemoveCustomContainerButton from './RemoveCustomContainerButton';
import ToggleHiddenCustomContainerButton from './ToggleHiddenCustomContainerButton';


class CustomContainerButtons extends Component {

  constructor(props){
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event){
    event.stopPropagation();
    this.props.removeCustomContainer(this.props.name);
  }

  render() {
    return(
      <div className="CustomContainerButtons" style={styles.customContainerButtons}>
        <ToggleHiddenCustomContainerButton name={this.props.name} toggleHiddenCustomContainer={this.props.toggleHiddenCustomContainer} hidden={this.props.hidden}/>
        <RemoveCustomContainerButton name={this.props.name} removeCustomContainer={this.props.removeCustomContainer}/>
      </div>
    )
  }

}

const styles = {
  customContainerButtons: {
    position: "absolute",
    right: 0,
    height: 35,
    width: 120,
    textAlign: "right"
  }
}

export default CustomContainerButtons;

import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHistory } from '@fortawesome/free-solid-svg-icons';
import { faClock } from '@fortawesome/free-regular-svg-icons'

import Pouch from '../../DB/Pouch';
import TodoListItem from './TodoListItem';
import openTodoDialog from '../TodoView/Todo/TodoDialog';
import { sortRegularTodos, sortReoccuringTodos, sortByLastEdit} from '../TodoView/Todo/Todo';

class TodoListView extends Component {

  constructor(props){
    super(props);

    this.pouch = new Pouch(this.props.user);

    this.state = {
      todos: [],
      showCompleted: false,
    }

    this.removeTodo = this.removeTodo.bind(this);
    this.completeTodo = this.completeTodo.bind(this);
    this.restoreTodo = this.restoreTodo.bind(this);
    this.editTodo = this.editTodo.bind(this);
    this.getTodos = this.getTodos.bind(this);
    this.onShowCompletedCheckboxChange = this.onShowCompletedCheckboxChange.bind(this);
    this.getTodosOnDBChange = this.getTodosOnDBChange.bind(this);
    this.getTodosOnDBChange(); //Make sure to bind to this before executing
  }

  getTodosOnDBChange(){
    this.pouch.db.changes({
      since: 'now',
      live: true
    }).on('change', this.getTodos);
  }

  componentWillMount(){
    this.getTodos();
  }

  async getTodos(){
    if(this.state){
      let todos = [];
      let sortedTodos = [];
      if(this.props.filter === "reoccuring"){
        todos = await this.pouch.getReoccuringTodos();
        sortedTodos = sortReoccuringTodos(todos);
      } else if(this.props.filter === "regular"){
        todos = await this.pouch.getRegularTodos(this.state.showCompleted);

        if(!this.state.showCompleted)
          sortedTodos = sortRegularTodos(todos);
        else
          sortedTodos = sortByLastEdit(todos); 

      }
      this.setState({
          todos: sortedTodos
      });
    }
  }

  removeTodo(todo){
    this.pouch.removeTodo(todo);
  }

  completeTodo(todo){
    this.pouch.completeTodo(todo);
  }

  restoreTodo(todo){
    this.pouch.restoreTodo(todo);
  }

  editTodo(todo){
    openTodoDialog(()=>{}, todo, todo.dueAt, this.pouch);
  }

  onShowCompletedCheckboxChange(event){
    this.setState({
      showCompleted: event.target.checked
    }, () => {
      this.getTodos();
    })
  }

  render() {

      const reoccuring = this.props.filter === "reoccuring" ? true : false;
      let colWidthOffsetXS = 0;
      let colWidthOffsetLG = 0;
      if(!reoccuring){
        colWidthOffsetXS = -2;
        colWidthOffsetLG = -1;
      }

      const todoList = this.state.todos.map((todo, index) => {
        return <TodoListItem
          todo={todo}
          key={todo._id}
          removeTodo={this.removeTodo}
          completeTodo={this.completeTodo}
          restoreTodo={this.restoreTodo}
          editTodo={this.editTodo}
          lastInList={index === this.state.todos.length-1}
          colWidthOffsetXS={colWidthOffsetXS}
          colWidthOffsetLG={colWidthOffsetLG}
          />;
      });

      return(
      <Container fluid={true} className="TodoListView" style={styles.TodoListView}>

        {
          reoccuring ? null :
          <Form.Group controlId="formBasicCheckbox" style={styles.checkbox}>
            <Form.Check type="checkbox" label="Show Completed Todos" onChange={this.onShowCompletedCheckboxChange}/>
          </Form.Group>
        }

        <Row style={styles.firstRow}>
          {!reoccuring ? <Col xs={2} lg={1}></Col> : null}
          <Col xs={7 + colWidthOffsetXS} lg={8 + colWidthOffsetLG}>Description</Col>
          <Col xs={3} lg={3}><FontAwesomeIcon icon={this.props.filter === "reoccuring" ? faHistory : faClock}/></Col>
          <Col xs={2} lg={1}></Col>
        </Row>
        {todoList.length > 0 ? todoList : <span style={styles.noTodosSaved}>Nothing to show here...</span>}
      </Container>
    )
  }

}

const styles = {
  TodoListView: {
    color: "black",
    textAlign: "center"
  },
  firstRow: {
    height: 35,
    fontSize: 20,
    textAlign: "center",
    background: "grey",
    boxShadow: "inset 0 0 5px #000000",
  },
  noTodosSaved: {
    color: "white",
    margin: "auto"
  },
  checkbox: {
    color: "white"
  }
}

export default TodoListView;
